<template>

  <main class="card hidden_overflow_tabela px-2 mt-2" >
    <div :class="{'container_filter_company' : backgroundColor}">
      <validation-observer>
        <b-form>
          <b-row
            class="pb-2"
            v-if="editGroup"
          >
            <b-col
              lg="12"
              sm="6"
            >
              <h3>Filtrar no grupo</h3>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col
                sm="6"
                :lg="editGroup ? '12' : '3'"
                class="px-1"
            >
              <b-form-group
                  label="Inserir o nome fantasia"
                  label-for="company_name"
                  label-class="font_size_label"
              >
                <validation-provider
                    #default="{errors}"
                    name="Nome fantasia"
                >
                  <b-form-input
                      id="company_name"
                      v-model="form.company_name"
                      placeholder="Insira o nome fantasia"
                      maxLength="255"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-input>

                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
                sm="6"
                :lg="editGroup ? '4' : '3'"
                class="px-1"
            >
              <b-form-group
                  label="CPF/CNPJ"
                  label-for="identifier_company"
                  label-class="font_size_label"
              >

                <b-form-input
                    id="identifier_company"
                    v-model="form.identifier_company"
                    placeholder="00.000.000/0000-00"
                    v-mask="computedMask"
                    maxLength="18"
                    autocomplete="off"
                    v-if="showIdentifierCompanyMask"
                >

                </b-form-input>
                <small  v-if="errorApi.identificador_empresa" class="text-danger">{{errorApi.identificador_empresa}}</small>
              </b-form-group>
            </b-col>

            <b-col
                sm="6"
                :lg="editGroup ? '4' : '3'"
                class="px-1"
            >
              <b-form-group
                  label="Status"
                  label-for="status"
                  label-class="font_size_label"
              >
                <validation-provider
                    #default="{errors}"
                    name="status"
                >
                  <v-select
                      id="status"
                      class="select_filter_company"
                      v-model="form.status"
                      :options="statusOptions"
                      placeholder="Selecione o Status"
                      label="descricao"
                      item-text="status"
                      item-value="status"
                      :clearable="false"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </v-select>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
                sm="6"
                :lg="editGroup ? '4' : '3'"
                class="px-1"
            >
              <b-form-group
                  label="Operador"
                  label-for="operator"
                  label-class="font_size_label"
              >
                <validation-provider
                    #default="{errors}"
                    name="Operador"
                >
                  <v-select
                      id="operator"
                      class="select_filter_company"
                      v-model="form.operator"
                      :options="operatorOptions"
                      placeholder="Selecione o operador"
                      label="descricao_operador"
                      item-text="descricao_operador"
                      item-value="descricao_operador"
                      :clearable="false"
                      @input="handleOperator"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </v-select>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      <Filters
          @actionSearch="search"
          @actionClear="clear"
          :disableHr="true"
          :leftButtons="leftButtons"
          :disableMarginBottomButton="true"
      />
    </div>
  </main>

</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BForm, BCol, BRow, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from '@/views/components/custom/filters/Filters.vue'
import VueCleave from 'vue-cleave-component'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Filters,
    vSelect,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    VueCleave
  },
  props: {
    filter: {
      required: false,
      type: Boolean,
    },
    group: {
      required: false,
    },
    leftButtons: {
      required: false,
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorApi: {
      required: true,
      default: () => ({}),
    },
    error: {
      cnpj: null,
    },
    editGroup: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchFilter: null,
      clearFlag: false,
      form: {
        company_name: '',
        identifier_company: '',
        operator: '',
        status: '',
      },
      showIdentifierCompanyMask: true,
      operatorOptions: [],
      statusOptions: ['Incompleto', 'Completo', 'Parcial'],
      operatorOptionsInit: [],
      noOptionsMessage: () => 'Desculpe, nenhuma opção encontrada.'
    }
  },
  watch: {
    filter() {
      this.fireEventFilter()
    },

    defaultSize() {
      this.$emit('defaultSizeChanged')
    },

    group(newGroup) {
      if (newGroup) {
        this.setOperatorOptions(newGroup)
      }
    },
    'errorApi.cnpj': function (identificador_empresa) {
      if (identificador_empresa !== undefined) {
        this.error.identificador_empresa = identificador_empresa
      }
    },
  },
  mounted() {
    this.fireEventFilter()
  },
  computed: {
    computedMask() {
      return this.form.identifier_company.length <= 11 ? '###.###.###-##' : '##.###.###/####-##'
    },
  },
  methods: {
    handleOperator(operator) {
      this.form.operator = operator
    },
    fireEventFilter() {
      this.searchFilter = {
        defaultSize: this.defaultSize,
        route: this.$router.currentRoute.name,
        nome_fantasia: this.form.company_name,
        status: this.form.status,
        operador: this.form.operator,
        identificador_empresa: this.form.identifier_company,
        filter: false,
      }

      this.$emit('filterTable', this.searchFilter)
    },

    setOperatorOptions(group) {
      if (this.operatorOptionsInit.length == 0) {
        this.operatorOptionsInit = group.operador
      }

      const resultOperator = group.operador.reduce((acc, currentOperator) => {
        const haveDescriptionOperatorDuplicate = acc.find(item => item.descricao_operador === currentOperator.descricao_operador)
        if (!haveDescriptionOperatorDuplicate) {
          acc.push(currentOperator)
        }

        return acc
      }, [])

      resultOperator.sort((a,b) => {
        if(a.descricao_operador < b.descricao_operador) return -1
        if(a.descricao_operador > b.descricao_operador) return 1
      })

      this.operatorOptions = resultOperator
    },

    search() {
      this.clearFlag = false
      this.searchFilter = {
        defaultSize: this.defaultSize,
        route: this.$router.currentRoute.name,
        nome_fantasia: this.form.company_name,
        status: this.form.status,
        operador: this.form.operator ? this.form.operator.descricao_operador : '',
        identificador_empresa: this.form.identifier_company,
        clear: this.clearFlag,
        filter: true,
      }

      if (this.operatorOptionsInit.length > 0) {
        this.operatorOptions = [];
        this.operatorOptions = [...this.operatorOptionsInit]
      }

      this.$emit('filterTable', this.searchFilter)
    },

    clear() {
      this.showIdentifierCompanyMask = false
      this.form.company_name = ''
      this.form.operator = ''
      this.form.status = ''
      this.form.identifier_company = ''
      this.clearFlag = true

      this.$nextTick(() => {
        this.showIdentifierCompanyMask = true
        this.searchFilter = {
          defaultSize: this.defaultSize,
          route: this.$router.currentRoute.name,
          status: this.form.status,
          operador: this.form.operator,
          identificador_empresa: this.form.identifier_company,
          nome_fantasia: this.form.company_name,
          clear: this.clearFlag,
          pagina: 1,
        }
        this.$emit('filterTable', this.searchFilter)
      })
    },
  },

}

</script>

<style scoped lang="scss">

.container_filter_company {
  border-radius: 5px;
  background-color: #F5F6F9;
  padding: 20px 20px;
}

.select_filter_company {
  background-color: white;
}
</style>
